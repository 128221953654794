@charset "UTF-8";
/*
=======================================================================
	Adaptation des polices (responsive)
=======================================================================
*/
@media only screen and (max-width: 767px) {
  h1, .h1 {
    font-size: 2.143em; }
  h2, .h2 {
    font-size: 1.714em; }
  h3, .h3 {
    font-size: 1.286em; }
  h4, .h4 {
    font-size: 1.143em; }
  .node-actualite .titre, .slider .titre {
    font-size: 1.286em; }
  .entete {
    font-size: 1.286em !important; } }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5; }

h1.logo {
  padding: 0;
  margin: 0; }

/*
=======================================================================
	Elements techniques
=======================================================================
*/
/* ---- Contenu téléchargeable ---- */
.file.file-document {
  background-color: #ECECEC; }
  .file.file-document > a .filefield-title, .file.file-document > a .filefield.file-size {
    padding-left: 10px; }
  .file.file-document > a:hover {
    color: #C81E32 !important; }

/* ---- Lien Texte Long ---- */
.link-container {
  width: 100%;
  height: 40px;
  text-align: right; }
  .link-container > a {
    /*position: absolute;
    right: 3%;*/ }

.container {
  max-width: 100%; }

/*
=======================================================================
	Colorbox
=======================================================================
*/
#colorbox, #cboxWrapper, #cboxContent, #cboxLoadedContent {
  max-width: 100%; }

/*
=======================================================================
    vidéos youtube
=======================================================================
*/
div.file-video-youtube {
  width: 100%;
  max-width: 640px;
  margin: 0 auto; }

.media-youtube-video {
  position: relative;
  /*padding-bottom: 56.25%;
  padding-top: 30px;*/
  overflow: hidden;
  /*height: 0;*/ }

iframe.media-youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.media-add-from-url .description {
  display: none; }

/*
=======================================================================
    Medias
=======================================================================
*/
.media_embed {
  position: relative;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }
  .media_embed iframe, .media_embed object, .media_embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.youtubevideowrap {
  width: 90%;
  max-width: 640px;
  margin: 0 auto; }

/*
=======================================================================
	Newsletter
=======================================================================
*/
#container-newsletter {
  padding: 15px; }

.newsletter-optional {
  display: none; }

/*
==================================================================================
   Recherche générale : contient la barre de recherche (header) + page recherche
==================================================================================
*/
.formulaire-recherche #edit-search-api-views-fulltext {
  height: 30px;
  border: 2px solid;
  color: #464646;
  background-color: #fff;
  padding-left: 30px;
  border-radius: 15px;
  box-shadow: none;
  transition: all 0.3s ease 0s; }

.formulaire-recherche #edit-submit-recherche {
  font-family: FontAwesome;
  content: "\f002";
  border: medium none;
  height: 30px;
  width: 30px;
  color: #464646;
  font-size: 1.2em;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0 20px 20px 0;
  position: absolute; }

#block-views-exp-recherche-generale-page {
  padding-top: 10px;
  padding-bottom: 9px; }

#body-content #block-views-exp-recherche-generale-page {
  background-color: transparent !important;
  margin-bottom: 30px; }

#body-content .formulaire-recherche {
  background-color: transparent !important;
  margin-bottom: 30px; }

@media only screen and (max-width: 1300px) {
  .header-top .header-content .formulaire-recherche #edit-search-api-views-fulltext {
    width: 100% !important; } }

/* ---- styles pour les résultats de la recherche ---- */
.recherche .items .item {
  margin: 15px 0; }
  .recherche .items .item .titre {
    font-size: 1.5em;
    color: #006699;
    margin-bottom: 10px; }
  .recherche .items .item .date {
    margin-bottom: 5px;
    font-size: 0.9em; }
  .recherche .items .item:hover .titre {
    color: #009EE3; }

/*
============================================================================================
	Header contient les blocs bean 'Votre agence', 'Contacts' et 'Recherche + Newsletter'
============================================================================================
*/
.menu-mobile {
  background-color: #FFF;
  z-index: 9999;
  padding-bottom: 5px;
  padding-top: 5px; }

.home.img-responsive {
  /*width: 100%;*/
  /* @TODO : remettre pour ancien logo */ }

.header-top .header-left {
  height: 100%;
  position: absolute;
  background-color: transparent; }

.header-top .header-right {
  background-color: #006699;
  height: 100%;
  position: absolute;
  right: 0; }
  .header-top .header-right:before {
    content: "";
    line-height: 0;
    font-size: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: -50px;
    left: -95px;
    border-style: solid;
    border-color: transparent #069 #069 transparent;
    border-width: 50px 0 150px 95px; }

.header-top #block-bean-contacts {
  height: 100%;
  position: relative;
  padding-top: 20px;
  padding-left: 50px; }
  .header-top #block-bean-contacts .texte {
    font-size: 1.09em;
    line-height: 1.2; }

.header-top #block-bean-votre-agence .texte {
  font-size: 1.09em;
  line-height: 1.2; }
  .header-top #block-bean-votre-agence .texte > h3 {
    font-weight: 900; }

.header-top #block-bean-contacts:before {
  content: "\f095";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #069;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 5em;
  transform: rotate(30deg); }

.header-top #block-bean-contacts .texte h3 {
  font-weight: 900; }

.header-top #block-bean-recherche {
  padding-bottom: 15px; }

.header-top #block-bean-newsletter-recherche .btn.btn-red {
  margin-left: -20px;
  width: auto; }

/* -----------------------------------------------------------
Gestion header right en fontion de la largeur de la fenêtre
------------------------------------------------------------ */
@media only screen and (max-width: 350px) {
  #block-bean-newsletter-recherche .btn.btn-red {
    White-Space: normal;
    margin: auto !important; } }

@media only screen and (max-width: 1023px) {
  #navbar .header-top .header-content.container {
    width: auto !important; } }

@media only screen and (max-width: 1200px) {
  .header-right:before {
    left: -75px !important;
    border-width: 50px 0 150px 75px !important; }
  .header-top #block-bean-contacts {
    height: 100%;
    position: relative;
    padding-top: 18px;
    padding-left: 30px; }
    .header-top #block-bean-contacts:before {
      margin-left: -20px; } }

@media only screen and (min-width: 1430px) and (max-width: 1594px) {
  .header-left {
    width: 65%; }
  .header-right {
    width: 35%; } }

@media only screen and (min-width: 1595px) and (max-width: 1994px) {
  .header-left {
    width: 62%; }
  .header-right {
    width: 38%; } }

@media only screen and (min-width: 1995px) {
  .header-left {
    width: 60%; }
  .header-right {
    width: 40%; } }

/*
=======================================================================
    Icônes web social header + footer
=======================================================================
*/
.social-icons a.accessibility {
  background: none repeat scroll 0 0 #5B5B5B;
  color: #FFF;
  text-decoration: none;
  font-size: 1.3em;
  display: inline-block;
  width: 29px;
  height: 29px;
  border-radius: 20px;
  padding: 7px;
  line-height: 1; }
  .social-icons a.accessibility span {
    display: none; }

/* ----- HEADER ----- */
.header .social-icons {
  position: absolute;
  top: 10px;
  left: -35px; }

/* ----- FOOTER ----- */
footer .social-icons a.accessibility {
  background: none repeat scroll 0 0 #009ee3;
  color: #006699;
  margin-right: 10px; }

footer #block-bean-web-social-footer {
  float: right; }

/*
=======================================================================
    Menu
=======================================================================
*/
#navbar .navbar-collapse {
  background: none repeat scroll 0 0 #5B5B5B !important;
  border: none !important;
  border-radius: 0 !important; }

#navbar .navbar-collapse .menu {
  float: left;
  padding: 1px; }
  #navbar .navbar-collapse .menu li {
    float: left;
    position: relative; }
    #navbar .navbar-collapse .menu li.leaf a:before {
      content: "";
      height: 30px;
      border-right: 1px solid #848484;
      position: absolute;
      right: 0;
      top: 7px; }
    #navbar .navbar-collapse .menu li:nth-child(1) a:before {
      border-left: 1px solid #848484;
      left: -1px; }
    #navbar .navbar-collapse .menu li.leaf:last-child a:before {
      border-right: none !important; }
    #navbar .navbar-collapse .menu li a {
      text-transform: uppercase;
      color: #FFF;
      font: 14px Helvetica,sans-serif;
      text-decoration: none;
      padding: 15px 20px; }
    #navbar .navbar-collapse .menu li:hover > ul {
      display: block; }
  #navbar .navbar-collapse .menu a:hover, #navbar .navbar-collapse .menu ul li:nth-child(3) a:hover {
    background-color: #C81E32 !important;
    font-weight: bold; }
  #navbar .navbar-collapse .menu li:nth-child(3) a:hover {
    background-color: #5a185a !important; }
  #navbar .navbar-collapse .menu:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0; }

.affix {
  position: fixed;
  width: 100%; }

#navbar .navbar-collapse .menu li.first.last.expanded > a {
  background-color: #C81E32;
  color: #FFF;
  border: 1px solid #C81E32;
  border-radius: 15px;
  text-transform: uppercase;
  padding: 2px 35px 2px 15px !important;
  margin: 10px 15px 12px 0; }
  #navbar .navbar-collapse .menu li.first.last.expanded > a:before {
    content: "\f13a";
    font-family: fontawesome;
    position: absolute;
    top: -2px;
    font-size: 1.4em;
    margin: auto;
    border-left: medium none;
    left: 103px;
    width: 25px;
    height: 25px;
    text-align: center; }

#navbar .navbar-collapse .menu li ul {
  z-index: 99;
  width: 350px;
  display: none;
  position: absolute;
  top: 45px;
  left: -8px;
  background-color: #FFF;
  border: 1px solid #848484;
  box-shadow: 2px 2px 2px 0 #5a5a5a; }
  #navbar .navbar-collapse .menu li ul li {
    float: none;
    box-shadow: 0 1px 0 #5a5a5a, 0 1px 0 #777777; }
    #navbar .navbar-collapse .menu li ul li:last-child {
      box-shadow: none; }
  #navbar .navbar-collapse .menu li ul a {
    padding: 10px;
    line-height: 1;
    text-transform: uppercase;
    color: #5a5a5a; }
    #navbar .navbar-collapse .menu li ul a:before {
      border-right: none !important;
      border-left: none !important;
      content: "\f138" !important;
      font-family: fontawesome;
      position: absolute;
      top: 0;
      font-size: 1.5em;
      margin: auto;
      left: 325px !important; }
    #navbar .navbar-collapse .menu li ul a:hover {
      background-color: #C81E32 !important;
      color: #FFF; }

#navbar #main-menu.collapse.in .menu.nav {
  float: none; }
  #navbar #main-menu.collapse.in .menu.nav li {
    float: none; }
    #navbar #main-menu.collapse.in .menu.nav li.first.last.expanded > a {
      background-color: transparent;
      border: none;
      padding: 15px 20px !important;
      margin: 0;
      border-radius: 0; }
    #navbar #main-menu.collapse.in .menu.nav li ul {
      width: auto;
      float: none;
      position: relative;
      background-color: transparent;
      border: none;
      box-shadow: none;
      top: 0;
      left: 0; }
      #navbar #main-menu.collapse.in .menu.nav li ul li {
        box-shadow: none; }
        #navbar #main-menu.collapse.in .menu.nav li ul li a {
          color: #FFF; }

#navbar #main-menu.collapsing .menu.nav {
  float: none; }
  #navbar #main-menu.collapsing .menu.nav li {
    float: none; }
    #navbar #main-menu.collapsing .menu.nav li .first.last.expanded > a {
      background-color: transparent; }

#navbar #main-menu.collapse.in .menu li a:before,
#navbar #main-menu.collapsing .menu li a:before {
  content: none !important; }

.navbar.collapse.navbar-collapse.affix {
  top: 0;
  width: 100% !important;
  z-index: 99999; }

/*
=======================================================================
   Breadcrumb
=======================================================================
*/
.frise {
  background-color: #ECECEC;
  background-image: url("../frise.png");
  background-size: 100% 20px;
  background-repeat: repeat-x;
  height: 25px;
  background-position: center center;
  padding: 10px 0;
  position: relative; }
  .frise .breadcrumb {
    position: relative;
    z-index: 5;
    background: none repeat scroll 0 0 #ECECEC;
    text-transform: uppercase;
    padding: 4px 10px;
    margin-top: -10px;
    border-radius: 0;
    font-size: 0.857em; }
    .frise .breadcrumb .breadcrumb-list {
      list-style: none;
      display: inline-block; }
      .frise .breadcrumb .breadcrumb-list > li {
        display: inline-block; }

/*
=======================================================================
    Slider Accueil
=======================================================================
*/
/* -------- Flèches de navigation ------- */
.slider {
  overflow: hidden; }
  .slider.hmax {
    max-height: 430px; }
  .slider .owl-prev, .slider .owl-next {
    position: absolute;
    top: 40%;
    width: 30px;
    height: 30px;
    border-radius: 20px !important;
    background-color: rgba(0, 0, 0, 0.6) !important; }
    .slider .owl-prev:hover, .slider .owl-next:hover {
      width: 30px;
      height: 30px;
      border-radius: 20px;
      background-color: #C81E32 !important; }
    .slider .owl-prev .fa, .slider .owl-next .fa {
      font-size: 1.4em !important;
      position: absolute;
      top: 6px; }
  .slider .owl-prev {
    left: 3%; }
    .slider .owl-prev .fa {
      left: 6px; }
  .slider .owl-next {
    right: 3%; }
    .slider .owl-next .fa {
      right: 6px; }

/* ------- contenu du slider ------- */
.slider {
  text-decoration: none; }
  .slider .titre {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFF; }
  .slider .pull-right {
    font-weight: normal;
    font-size: 0.8em;
    padding-right: 25px;
    text-transform: none; }
    .slider .pull-right:hover {
      color: #C81E32; }

/* -------- boutons d'entrée de droite ------- */
.item {
  position: relative; }

/* hack Chrome / Safari */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .slider-buttons-right {
    margin-top: 55px !important; } }

.slider-buttons-right {
  margin-top: 56px;
  color: #FFF;
  text-transform: uppercase;
  height: 366px; }
  .slider-buttons-right .item {
    height: 33.3333%; }
    .slider-buttons-right .item:hover {
      background-color: #C81E32 !important; }
    .slider-buttons-right .item .title {
      font-size: 1.5em;
      padding: 35px 10px 0; }
    .slider-buttons-right .item .subtitle {
      font-size: 1.2em;
      padding: 0 10px; }
  .slider-buttons-right .item:first-child {
    background-color: #5B5B5B; }
  .slider-buttons-right .item:nth-child(2) {
    background-color: #006699; }
  .slider-buttons-right .item:last-child {
    background-color: #0099E3; }

@media only screen and (max-width: 767px) {
  .slider-buttons-right {
    height: auto !important;
    margin-top: 20px;
    /* .item:first-child, .item:last-child  {
      display: none !important;
    }*/ }
    .slider-buttons-right .title {
      padding: 10px 10px 0 !important; }
    .slider-buttons-right .subtitle {
      padding: 0px 10px 10px !important; } }

@media only screen and (min-width: 1200px) {
  .slider-buttons-right {
    height: 429px !important; } }

/*
=======================================================================
    Vue page : bloc carrefour, bloc pages_soeurs
=======================================================================
*/
/* home : boutons d'entrée mauves */
.home.entree-mauves .items {
  margin-top: 30px;
  color: #5A185A; }
  .home.entree-mauves .items .thumbnail {
    border-radius: 0;
    border-color: #5A185A;
    height: 278px; }
    .home.entree-mauves .items .thumbnail:hover {
      color: #AC8BAC;
      border-color: #AC8BAC; }
      .home.entree-mauves .items .thumbnail:hover:before {
        color: #5A185A; }
    .home.entree-mauves .items .thumbnail:before {
      content: "\f138";
      font-family: FontAwesome;
      color: #5A185A;
      font-size: 1.5em;
      position: absolute;
      top: 5px;
      right: 15px;
      height: 25px;
      width: 25px; }
    .home.entree-mauves .items .thumbnail .h4 {
      text-align: center;
      text-transform: uppercase;
      padding: 0 20px;
      line-height: 1.4em; }
    .home.entree-mauves .items .thumbnail .icone {
      max-width: 60%;
      text-align: center;
      margin: auto; }

@media only screen and (max-width: 767px) {
  .home.entree-mauves .items {
    margin-top: 10px; }
    .home.entree-mauves .items .thumbnail {
      height: auto;
      margin-bottom: 10px; } }

@media only screen and (max-width: 1023px) {
  .home.entree-mauves .items .thumbnail .icone {
    max-width: 50%; } }

/* Bloc page carrefour (boutons d'entrée (bleus) vers les pages de niveau 3) */
.page-carrefour-item > .thumbnail {
  padding: 0;
  background: none repeat scroll 0 0 transparent;
  border: 0 none;
  margin-bottom: 10px; }
  .page-carrefour-item > .thumbnail > a.top {
    width: 100%;
    height: 210px;
    display: block;
    position: relative;
    background: none repeat scroll 0 0 #006699;
    overflow: hidden;
    color: #F4F4F4;
    text-decoration: none;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px; }
    .page-carrefour-item > .thumbnail > a.top:before {
      content: "\f138";
      font-family: FontAwesome;
      color: #fff;
      font-size: 1.5em;
      position: absolute;
      top: 5px;
      right: 0;
      height: 25px;
      width: 25px; }
    .page-carrefour-item > .thumbnail > a.top:hover, .page-carrefour-item > .thumbnail > a.top:active {
      width: 100%;
      background: #C81E32;
      overflow: hidden;
      text-decoration: none; }
    .page-carrefour-item > .thumbnail > a.top:hover h2, .page-carrefour-item > .thumbnail > a.top:active h2 {
      font-weight: bold; }
    .page-carrefour-item > .thumbnail > a.top > h2 {
      font-size: 1.143em;
      padding-bottom: 15px;
      color: #F4F4F4;
      text-transform: uppercase;
      line-height: 1.4em;
      margin-top: 25px;
      padding-right: 5px; }
    .page-carrefour-item > .thumbnail > a.top.bg-mauve {
      background-color: #5A185A; }
      .page-carrefour-item > .thumbnail > a.top.bg-mauve:hover, .page-carrefour-item > .thumbnail > a.top.bg-mauve:active {
        background-color: #006699; }
  .page-carrefour-item > .thumbnail .caption {
    padding: 9px;
    color: #333; }

.p-924 .page-carrefour-item > .thumbnail > a.top {
  background: none repeat scroll 0 0 #5A185A; }
  .p-924 .page-carrefour-item > .thumbnail > a.top:hover, .p-924 .page-carrefour-item > .thumbnail > a.top:active {
    background: #006699; }

.p-924 .page-carrefour-item .top.active {
  background-color: #006699 !important; }

.p-924 .contenu-principal a {
  color: #5A185A; }

.p-924 .btn-default.btn-red, .p-924 .btn-red {
  background-color: #5A185A;
  color: #FFF !important;
  border: 1px solid #5A185A;
  border-radius: 15px;
  text-transform: uppercase;
  padding: 0 15px !important;
  margin: 10px;
  background-image: none; }

.p-924 .btn-default.btn-red:hover, .p-924 .btn-default.btn-red:active, .p-924 .btn-default.btn-red:focus, .p-924 .btn-red:hover, .p-924 .btn-red:active, .p-924 .btn-red:focus {
  background-color: #FFF;
  color: #5A185A !important;
  border: 1px solid #5A185A;
  border-radius: 15px;
  text-transform: uppercase;
  padding: 0 15px !important; }

@media only screen and (max-width: 767px) {
  .page-carrefour-item .thumbnail > a.top {
    height: auto; } }

.mot-cles-container {
  color: white;
  padding: 2px;
  margin: 26px 0; }
  .mot-cles-container .mot-cles a {
    color: #FFF; }
    .mot-cles-container .mot-cles a:hover {
      color: #ECECEC; }

.mot-cle-titre {
  font-size: 1.3em;
  font-weight: bold; }

.intro {
  font-size: 1.3em; }

/* ------- Bloc pages_soeurs ------- */
.page-carrefour-item .top.active {
  background-color: #C81E32 !important; }

/*
=======================================================================
    Actualités
=======================================================================
*/
.actu {
  min-height: 150px;
  max-height: 550px;
  overflow: hidden; }

.actu-listing .actu-row-color:nth-child(2n+1) {
  background-color: #006699; }

.actu-listing .actu-row-color:nth-child(2n) {
  background-color: #009EE3; }

.actu-listing .actu-row-color:hover {
  background-color: #C81E32; }

.actu-listing .actu-list-row {
  position: relative;
  color: #FFF;
  min-height: 100px;
  /* IMG{
       height: 250px;
       width: 100%;
     }*/ }
  .actu-listing .actu-list-row .item-meta {
    margin: 10px 0; }
  .actu-listing .actu-list-row .read-more .fa {
    font-size: 1.7em;
    float: right;
    margin-top: 10px; }
  .actu-listing .actu-list-row .item-title.h3:hover {
    font-weight: bold; }

.actu-container .actu-social {
  text-align: right;
  padding: 20px 0;
  font-size: 1.6em; }
  .actu-container .actu-social .fa {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    background-color: #006699;
    text-align: center;
    line-height: 1.5;
    color: #FFF;
    margin-left: 5px; }

.actu-container .actu-img-principale IMG {
  width: 100%; }

.actu-container .intro {
  text-align: justify; }

.actu-container .content {
  text-align: justify; }

.actu-container .actu-links {
  text-align: right; }

.actu-container a.same-subject {
  text-transform: uppercase; }

.actu-container .conteneur {
  padding: 10px 50px; }

.see-all {
  white-space: normal; }

/* ----- affichage de la vidéo ----- */
.video-actu-bas .media-youtube-player {
  display: block;
  margin: auto;
  width: 80%; }

/*
===============================================================================
   Légendes images
===============================================================================
*/
.texte-image .legende {
  color: #464646;
  font-style: italic;
  margin-bottom: 20px; }

/*
===============================================================================
    Agenda :
   - contient les styles du node--agenda.tpl.php, et des vue-agenda
===============================================================================
*/
.content-evenement .row.m-t-20 {
  display: flex; }

.content-evenement .date-display-range {
  display: inline-block; }

.content-evenement .texte {
  padding: 10px;
  width: 100%; }

.content-evenement .bg-blue.is-white {
  height: 100%;
  font-size: 1.15em; }
  .content-evenement .bg-blue.is-white .texte {
    text-align: center;
    padding: 20px 0;
    text-transform: uppercase;
    justify-content: center; }

@media only screen and (max-width: 992px) {
  .content-evenement .row.m-t-20 {
    display: block !important; } }

/* ----- bloc agenda accueil ----- */
.agenda-home .bg-blue.m-b-10:hover {
  background-color: #C81E32; }

.agenda-home .item a .date {
  color: #006699; }

.agenda-home .item a .titre {
  margin-left: 15px;
  color: #464646; }

.agenda-home .item a:hover .date {
  color: #009EE3; }

.agenda-home .item a:hover .titre {
  color: #848484; }

.agenda-home > hr:last-of-type {
  display: none; }

/* ----- page agenda ----- */
.agenda-page .row:hover {
  background-color: #C81E32; }
  .agenda-page .row:hover .top .titre, .agenda-page .row:hover .top .objet, .agenda-page .row:hover .top .more-link {
    color: #FFF; }
  .agenda-page .row:hover .top .titre {
    font-weight: bold; }

.agenda-page .row .top {
  display: flex;
  width: 100%; }
  .agenda-page .row .top .date, .agenda-page .row .top .lieu {
    background-color: #464646;
    color: #FFF;
    font-size: 1.15em;
    padding: 15px; }
  .agenda-page .row .top .date {
    background-color: #009EE3 !important; }
  .agenda-page .row .top .objet, .agenda-page .row .top .more-link {
    color: #464646;
    padding: 5px 20px; }
  .agenda-page .row .top .titre {
    color: #006699;
    padding: 5px 20px;
    font-size: 1.2em; }

@media only screen and (max-width: 767px) {
  .agenda-page .row .top {
    display: block !important; } }

/* ----- pagination ----- */
.view-id-recherche .view-display-id-agenda .pagination-wrap .pagination {
  float: right; }
  .view-id-recherche .view-display-id-agenda .pagination-wrap .pagination > li > a {
    margin-right: 5px;
    border-radius: 0;
    background-color: #848484;
    color: #FFF; }
    .view-id-recherche .view-display-id-agenda .pagination-wrap .pagination > li > a:hover {
      background-color: #C81E32; }
  .view-id-recherche .view-display-id-agenda .pagination-wrap .pagination .active a {
    background-color: #009EE3; }

/* ----- formulaire de recherche ----- */
.formulaire-recherche-agenda .date-padding .control-label {
  display: none !important; }

.formulaire-recherche-agenda .entete {
  color: #006699;
  font-size: 2.5em;
  text-transform: uppercase; }

.formulaire-recherche-agenda .widgets {
  padding: 30px 10px;
  margin-bottom: 50px;
  background-color: #009EE3; }
  .formulaire-recherche-agenda .widgets .form-control, .formulaire-recherche-agenda .widgets .form-group, .formulaire-recherche-agenda .widgets #edit-field-date-debut-value, .formulaire-recherche-agenda .widgets #edit-field-date-fin-value {
    width: 100% !important; }
  .formulaire-recherche-agenda .widgets .col-sm-6.col-xs-12 {
    margin-bottom: 10px; }
  .formulaire-recherche-agenda .widgets .form-select {
    /*  height: 100%;
      background-color: transparent;
      border: none !important;
      box-shadow: none;
      padding: 0;*/ }
  .formulaire-recherche-agenda .widgets .label {
    font-size: 1em; }
    .formulaire-recherche-agenda .widgets .label .fa {
      font-size: 3em;
      right: -26px;
      position: relative;
      bottom: -12px; }
  .formulaire-recherche-agenda .widgets .submit-button {
    text-align: center; }
    .formulaire-recherche-agenda .widgets .submit-button #edit-submit-recherche-agenda {
      border: none;
      border-radius: 0;
      background-color: #006699;
      color: #FFF;
      position: relative;
      bottom: -20px;
      background-image: none; }
      .formulaire-recherche-agenda .widgets .submit-button #edit-submit-recherche-agenda:hover {
        background-color: #C81E32;
        font-weight: bold; }

/*
=======================================================================================
    ANNUAIRES
   - contient les styles du node--entreprise.tpl.php + node--ancien-etudiant.tpl.php
    et des (vues) entreprise--annuaire-reseau-pro + entreprise-annuaire-exportateurs
=======================================================================================
*/
.node-entreprise .mot-cles a {
  color: #FFF; }
  .node-entreprise .mot-cles a:hover {
    color: #C81E32; }

.node-entreprise .thumbnail {
  padding: 15px 15px 15px 0;
  box-shadow: none; }
  .node-entreprise .thumbnail .top {
    width: 100%;
    height: 210px;
    display: block;
    position: relative;
    background: none repeat scroll 0 0 #006699;
    overflow: hidden;
    color: #F4F4F4;
    text-decoration: none;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: uppercase;
    font-size: 1.5em; }
    .node-entreprise .thumbnail .top > span {
      position: relative;
      top: 40%; }

.node-entreprise .texte {
  padding: 15px;
  line-height: 3;
  text-transform: uppercase;
  margin-top: 15px; }
  .node-entreprise .texte .mail, .node-entreprise .texte .siteweb {
    text-transform: none; }

@media only screen and (max-width: 992px) {
  .node-entreprise .thumbnail {
    padding: 0; }
    .node-entreprise .thumbnail .top {
      height: auto; }
  .node-entreprise .texte {
    line-height: 2;
    margin-top: 0; } }

.annuaire .is-white {
  text-transform: uppercase;
  padding: 5px; }

.annuaire .listing .item {
  padding: 15px;
  margin: 15px 0;
  color: #FFF; }
  .annuaire .listing .item:nth-child(2n+1) {
    background-color: #006699; }
  .annuaire .listing .item:nth-child(2n) {
    background-color: #009EE3; }
  .annuaire .listing .item:hover {
    background-color: #C81E32; }
  .annuaire .listing .item .fa {
    position: absolute;
    right: 25px;
    font-size: 1.5em; }
  .annuaire .listing .item .titre {
    font-size: 1.2em;
    text-transform: uppercase; }

/* ----- Filtres exposés ----- */
.formulaire-recherche-entreprise .entete {
  font-size: 1.3em;
  text-transform: uppercase;
  color: #FFF;
  background-color: #A5A5A5;
  padding: 5px 20px; }

.formulaire-recherche-entreprise .widgets {
  background-color: #C81E32;
  padding: 15px; }
  .formulaire-recherche-entreprise .widgets .label {
    font-size: 1em; }
  .formulaire-recherche-entreprise .widgets .chosen-container.chosen-container-single.form-control {
    background: transparent none repeat scroll 0 0;
    box-shadow: none;
    border: none;
    width: 100% !important;
    padding: 0; }
  .formulaire-recherche-entreprise .widgets .chosen-container-single .chosen-drop {
    margin-top: -10px; }

.formulaire-recherche-entreprise .submit-button {
  background-color: #C81E32; }
  .formulaire-recherche-entreprise .submit-button #edit-submit-entreprises, .formulaire-recherche-entreprise .submit-button #edit-submit-anciens-etudiants {
    padding: 5px;
    background: transparent none repeat scroll 0 0;
    border: medium none;
    color: #FFF;
    font-size: 1.2em;
    text-transform: uppercase;
    width: 100%; }
  .formulaire-recherche-entreprise .submit-button:hover {
    opacity: 0.9; }
  .formulaire-recherche-entreprise .submit-button:after {
    top: 100%;
    left: 50%;
    border: 1px solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    border-top-color: #C81E32;
    border-width: 9px 10px 10px 10px;
    margin-left: -10px; }

/*
=======================================================================
    ANNONCES : Contient les styles nécessaires à la mise en forme des
    contenu et des vues en relation avec le type de contenu 'annonce'
=======================================================================
*/
.annonce-bouton-entree .top {
  display: flex !important;
  align-items: center;
  justify-content: center; }

.annonce-img-principale IMG {
  padding: 0 10px; }

.annonce {
  min-height: 150px;
  max-height: 350px;
  overflow: hidden; }

.prix {
  font-size: 1.2em; }

.bouton-action {
  position: relative;
  height: 45px; }
  .bouton-action .btn-add-annonce {
    position: absolute;
    right: 0;
    font-size: 1.2em;
    white-space: normal; }

/*
==============================================================================
       Vidéo Home
==============================================================================
*/
.video-home .bg-red:hover {
  background-color: #006699; }

.bg-titre-bloc-home {
  padding: 5px 15px;
  position: relative;
  color: #FFF;
  text-transform: uppercase; }

/*
==============================================================================
    Page-accordéon (Styles pour l'affichage des accordéons) + Blocs_sidebar
==============================================================================
*/
.content-accordion .panel-group {
  margin-bottom: 5px; }
  .content-accordion .panel-group:last-of-type {
    margin-bottom: 20px; }

.content-accordion .panel-default {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important; }

.content-accordion .panel-heading {
  border: none !important;
  border-radius: 0 !important; }

.content-accordion .panel-heading.accordion-actif {
  background-color: #C81E32 !important;
  color: #FFF !important;
  background-image: none; }

.content-accordion .panel-heading.accordion-inactif {
  background-color: #464646 !important;
  color: #FFF !important;
  background-image: none; }

.content-accordion .indicator {
  font-size: 1.4em;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  height: 100%;
  right: 0px; }

.content-accordion .panel-title {
  position: relative;
  line-height: 1.4em; }
  .content-accordion .panel-title .accordion-lien {
    padding-right: 30px; }

.p-924 .content-accordion .panel-heading.accordion-actif {
  background-color: #5A185A !important; }

/* ----- Blocs dans la sidebar right ----- */
.side_bar_right > div {
  background-color: #009EE6;
  color: #FFF;
  padding: 5px;
  margin: 0 0 20px; }
  .side_bar_right > div .titre {
    font-size: 1.5em;
    margin-bottom: 10px; }
  .side_bar_right > div .top {
    color: #FFF;
    margin-bottom: 5px; }
    .side_bar_right > div .top:hover {
      color: #C81E32 !important; }
    .side_bar_right > div .top .fa {
      color: #FFF; }
  .side_bar_right > div .mail > a, .side_bar_right > div .telephone > a {
    color: #FFF; }
    .side_bar_right > div .mail > a:hover, .side_bar_right > div .telephone > a:hover {
      color: #C81E32; }
  .side_bar_right > div .nom-prenom {
    font-size: 1.4em; }

.side_bar_right .node-texte-image-lien {
  position: relative !important; }
  .side_bar_right .node-texte-image-lien:hover {
    background-color: #C81E32; }

.p-924 .side_bar_right > div {
  background-color: #5A185A; }
  .p-924 .side_bar_right > div .top:hover {
    color: #009EE6 !important; }
  .p-924 .side_bar_right > div .mail > a:hover, .p-924 .side_bar_right > div .telephone > a:hover {
    color: #009EE6; }

.p-924 .side_bar_right .node-texte-image-lien {
  position: relative !important; }
  .p-924 .side_bar_right .node-texte-image-lien:hover {
    background-color: #009EE6; }

.side_bar_right .map div {
  color: #777; }

.sidebar-blocs .titre .fa {
  transform: rotate(30deg);
  font-size: 1.8em; }

.sidebar-blocs .nom {
  display: inline-block; }

.sidebar-blocs .link {
  color: #FFF; }
  .sidebar-blocs .link:hover {
    color: #C81E32; }

.sidebar-blocs div.node.node-contenus-telechargeables {
  background-color: #464646 !important; }
  .sidebar-blocs div.node.node-contenus-telechargeables .file.file-document {
    background-color: transparent;
    text-align: center; }
    .sidebar-blocs div.node.node-contenus-telechargeables .file.file-document .fa {
      width: 100%;
      font-size: 5px; }
    .sidebar-blocs div.node.node-contenus-telechargeables .file.file-document .filefield-title, .sidebar-blocs div.node.node-contenus-telechargeables .file.file-document .filefield.file-size {
      color: #FFF; }

.mail, .telephone {
  font-size: 1.25em;
  word-wrap: break-word; }

.mail {
  font-size: 1em; }

/*
=======================================================================
    FAQ
=======================================================================
*/
.node-faq .question {
  font-size: 2em;
  margin: 10px; }

.node-faq .rep_courte {
  padding: 5px; }

.node-faq .rep_detaillee {
  padding: 5px; }

#colorbox .node-faq {
  margin-top: 60px;
  margin-left: 20px; }

.bloc-faq .question > a {
  color: #FFF; }
  .bloc-faq .question > a:hover {
    color: #C81E32; }

/*
=======================================================================
    SurFooter : Carousels Prestation et Services + Partenaires
=======================================================================
*/
/* ------ Flèches de navigation ----- */
.carousel-surfooter {
  max-height: 250px; }
  .carousel-surfooter .owl-nav {
    position: absolute;
    top: -50px;
    right: 0;
    font-size: 1.3em;
    text-align: center; }
  .carousel-surfooter .owl-prev, .carousel-surfooter .owl-next {
    width: 25px;
    height: 25px;
    background-color: #848484 !important;
    border-radius: 0 !important; }
    .carousel-surfooter .owl-prev:hover, .carousel-surfooter .owl-next:hover {
      width: 25px;
      height: 25px;
      background-color: #C81E32 !important; }

/* ------- éléments + contenus du carousel ------ */
.surfooter.prestation-service-item .thumbnail, .surfooter.partenaire-item .thumbnail {
  border: none;
  padding: 0;
  margin-bottom: 0;
  box-shadow: none;
  position: relative; }

.surfooter.prestation-service-item .top, .surfooter.partenaire-item .top {
  width: 100%;
  display: block;
  overflow: hidden;
  color: #F4F4F4;
  text-decoration: none;
  margin: auto; }

.surfooter.prestation-service-item .titre, .surfooter.partenaire-item .titre {
  position: absolute;
  bottom: 0;
  color: #FFF;
  font-size: 1.25em;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%; }

.surfooter.partenaire-item .thumbnail > img {
  border: 1px solid #5A5A5A; }

@media only screen and (max-width: 767px) {
  .view.view-prestations-services .h3.is-blue, .view.view-partenaires .h3.is-blue {
    font-size: 1.286em; } }

/*
=======================================================================
    Footer
=======================================================================
*/
footer a {
  color: #fff; }

footer .footer-content a:hover {
  color: #fff;
  text-decoration: none;
  background-color: transparent; }

footer > .top {
  color: #FFF;
  font-size: 0.929em; }

footer .top .footer-left, footer .middle .footer-right {
  background-color: #006699; }

footer .top .footer-right {
  background-color: #a5a5a5; }

footer .middle .footer-left {
  background-color: #5b5b5b; }

footer .bottom {
  background-color: #787878; }

footer > .top, footer > .middle, footer > .bottom {
  position: relative;
  min-height: 20px; }

footer .footer-right {
  left: 75%;
  height: 100%;
  position: absolute;
  width: 25%;
  right: 0;
  z-index: -1; }

footer .footer-left {
  height: 100%;
  position: absolute;
  width: 75%;
  left: 0;
  z-index: -1; }

footer .middle #block-bean-ccism-map-0 {
  color: #FFF; }

footer .bottom ul.menu li a:hover, footer .bottom ul.menu li a:focus, footer .bottom ul.menu li a:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent; }

footer .middle .footer-left a:hover, footer .middle .footer-left a:focus, footer .middle .footer-left a:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent; }

footer .middle .footer-left .region-footer-middle-left {
  margin-left: 25px; }

footer .middle ul.menu ul li.leaf a {
  font-weight: normal;
  padding-bottom: 0;
  border-bottom: none;
  text-transform: none; }

footer .middle ul.menu li.expanded {
  margin-bottom: 20px; }
  footer .middle ul.menu li.expanded a {
    font-weight: 900;
    padding-bottom: 5px;
    border-bottom: 2px solid #ECECEC;
    text-transform: uppercase;
    font-size: 0.786em; }

footer .bottom .h2, footer .middle .h2 {
  display: none; }

footer .bottom {
  text-align: center;
  padding: 5px 0; }
  footer .bottom .menu li {
    display: inline-block; }
    footer .bottom .menu li a {
      border-right: 2px solid #fff;
      padding: 0 15px;
      font-weight: 600; }
    footer .bottom .menu li:last-child a {
      border-right: none !important; }

@media only screen and (max-width: 1023px) {
  footer .top .footer-left {
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1; }
  footer .middle .footer-right {
    left: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1; } }

/* ----- Footer contact / localiser ----- */
footer .mobile {
  line-height: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #FFF; }
  footer .mobile .fa {
    vertical-align: middle;
    margin-right: 5px;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    font-size: 1.5em;
    margin-left: 10px;
    text-align: center;
    background-color: #FFF;
    color: #A5A5A5;
    line-height: 30px; }
  footer .mobile .bg-red .fa {
    color: #C81E32 !important; }
  footer .mobile .col-xs-6.bg-lightgray:before {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: -25px;
    content: "";
    border-top: 50px solid transparent;
    border-right: 25px solid #A5A5A5; }

footer a:hover {
  color: #C81E32; }

@media only screen and (max-width: 767px) {
  footer .mobile {
    font-size: 1.1em; }
    footer .mobile .fa {
      margin-right: 0;
      margin-left: 0;
      border-radius: 20px;
      width: 1.2em;
      height: 1.2em;
      font-size: 1.2em;
      line-height: 1.2em; }
    footer .mobile .col-xs-6.bg-red {
      padding: 5px !important; }
    footer .mobile .col-xs-6.bg-lightgray {
      padding: 5px !important; }
      footer .mobile .col-xs-6.bg-lightgray:before {
        left: -20px;
        border-top: 60px solid transparent;
        border-right: 20px solid #A5A5A5; }
  footer a:hover {
    color: #C81E32; } }

/*
============================================
  Sitemap
============================================
*/
#site-map .description {
  display: none; }

.site-map-books > ul > li {
  margin-bottom: 10px; }

.site-map-books ul ul ul ul {
  display: none; }

.site-map-books li a {
  text-decoration: none;
  font-size: 1em; }

.site-map-books li li {
  margin-top: 50px; }
  .site-map-books li li a {
    text-decoration: underline;
    font-size: 1.2em; }

.site-map-books li li li {
  margin-top: 0px; }

.site-map-books li li li a {
  text-decoration: none;
  font-size: 1em; }

.site-map-books li li li li {
  margin-top: 0px; }

.site-map-books li li li li a {
  text-decoration: none;
  font-size: 1em; }

/*
============================================
  Cookies
============================================
*/
#sliding-popup.sliding-popup-bottom {
  background-color: rgba(0, 0, 0, 0.8) !important; }

#sliding-popup .popup-content #popup-text {
  margin: 0;
  padding: 10px;
  max-width: 80%;
  font-weight: normal; }
  #sliding-popup .popup-content #popup-text .link {
    color: #FFF !important;
    font-weight: bold; }
    #sliding-popup .popup-content #popup-text .link:hover {
      color: #C81E32 !important; }

#sliding-popup .popup-content #popup-buttons .find-more-button {
  display: none; }

#sliding-popup .popup-content #popup-buttons .agree-button {
  margin: 10px 0;
  box-shadow: none;
  border: 1px solid #FFF;
  background: transparent none repeat scroll 0 0;
  color: #FFF;
  text-shadow: none;
  padding: 0 8px; }
  #sliding-popup .popup-content #popup-buttons .agree-button:hover {
    color: #C81E32;
    border-color: #C81E32;
    background: transparent none repeat scroll 0 0; }
