/*
=======================================================================
	Adaptation des polices (responsive)
=======================================================================
*/
@media only screen and (max-width: 767px)  {
  h1, .h1 {
    font-size: 2.143em;
  }
  h2, .h2 {
    font-size: 1.714em;
  }
  h3, .h3 {
    font-size: 1.286em;
  }
  h4, .h4 {
    font-size: 1.143em;
  }
  .node-actualite, .slider {
    .titre {
      font-size: 1.286em;
    }
  }
  .entete {
    font-size: 1.286em !important;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5;
}
h1.logo{
  padding:0;
  margin:0;
}
/*
=======================================================================
	Elements techniques
=======================================================================
*/
/* ---- Contenu téléchargeable ---- */
.file.file-document {
  background-color: #ECECEC;
  > a {
    .filefield-title, .filefield.file-size {
      padding-left: 10px;
    }
    &:hover {
      color: #C81E32 !important;
    }
  }
}

/* ---- Lien Texte Long ---- */
.link-container {
  width: 100%;
  height: 40px;
  text-align: right;
  > a {
    /*position: absolute;
    right: 3%;*/
  }
}
.container {
  max-width: 100%;
}

/*
=======================================================================
	Colorbox
=======================================================================
*/
#colorbox, #cboxWrapper, #cboxContent, #cboxLoadedContent {
  max-width: 100%;
}


/*
=======================================================================
    vidéos youtube
=======================================================================
*/


div.file-video-youtube {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
}
.media-youtube-video {
  position: relative;
  /*padding-bottom: 56.25%;
  padding-top: 30px;*/
  overflow: hidden;
  /*height: 0;*/
}

iframe.media-youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.media-add-from-url .description{
  display: none;
}

/*
=======================================================================
    Medias
=======================================================================
*/
.media_embed {
  position: relative;
  //padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.youtubevideowrap {
  width: 90%;
  max-width: 640px;
  margin: 0 auto;
}

/*
=======================================================================
	Newsletter
=======================================================================
*/
#container-newsletter{
  padding:15px;
}

.newsletter-optional{
  display:none;
}

/*
==================================================================================
   Recherche générale : contient la barre de recherche (header) + page recherche
==================================================================================
*/
.formulaire-recherche {
  #edit-search-api-views-fulltext {
    height: 30px;
    border: 2px solid;
    color: #464646;
    background-color: #fff;
    padding-left: 30px;
    border-radius: 15px;
    box-shadow: none;
    transition: all 0.3s ease 0s;
  }
  #edit-submit-recherche {
    font-family: FontAwesome;
    content: "\f002";
    border: medium none;
    height: 30px;
    width: 30px;
    color: #464646;
    font-size: 1.2em;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0 20px 20px 0;
    position: absolute;
  }
}
#block-views-exp-recherche-generale-page {
  padding-top: 10px;
  padding-bottom: 9px;
}
#body-content #block-views-exp-recherche-generale-page {
  background-color: transparent !important;
  margin-bottom: 30px;
}
#body-content .formulaire-recherche {
  background-color: transparent !important;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1300px)  {
  .header-top .header-content .formulaire-recherche #edit-search-api-views-fulltext {
    width: 100% !important;
  }
}

/* ---- styles pour les résultats de la recherche ---- */
.recherche {
  .items {
    .item {
      margin: 15px 0;
      .titre {
        font-size: 1.5em;
        color: #006699;
        margin-bottom: 10px;
      }
      .date {
        margin-bottom: 5px;
        font-size: 0.9em;
      }
      &:hover .titre {
        color: #009EE3;
      }
    }
  }
}

/*
============================================================================================
	Header contient les blocs bean 'Votre agence', 'Contacts' et 'Recherche + Newsletter'
============================================================================================
*/
.menu-mobile {
  background-color: #FFF;
  z-index: 9999;
  padding-bottom: 5px;
  padding-top: 5px;
}
.home.img-responsive {
  /*width: 100%;*/ /* @TODO : remettre pour ancien logo */
}
.header-top {
  .header-left {
    height: 100%;
    position: absolute;
    background-color: transparent;
  }
  .header-right {
    background-color: #006699;
    height: 100%;
    position: absolute;
    right: 0;
    &:before {
      content: "";
      line-height: 0;
      font-size: 0;
      width: 0;
      height: 0;
      position: absolute;
      top: -50px;
      left: -95px;
      border-style: solid;
      border-color: transparent #069 #069 transparent;
      border-width: 50px 0 150px 95px;
    }
  }
  #block-bean-contacts {
    height: 100%;
    position: relative;
    padding-top: 20px;
    padding-left: 50px;
    .texte {
      font-size: 1.09em;
      line-height: 1.2;
    }
  }
  #block-bean-votre-agence .texte {
    font-size: 1.09em;
    line-height: 1.2;
    > h3 {

      font-weight: 900;
    }
  }
  #block-bean-contacts {
    &:before {
      content: "\f095";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      color: #069;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 5em;
      transform: rotate(30deg);
    }
    .texte h3 {
      font-weight: 900;
    }
  }
  #block-bean-recherche {
    padding-bottom: 15px;
  }
  #block-bean-newsletter-recherche .btn.btn-red {
    margin-left: -20px;
    width: auto;
  }
}


/* -----------------------------------------------------------
Gestion header right en fontion de la largeur de la fenêtre
------------------------------------------------------------ */
@media only screen and (max-width: 350px)  {
  #block-bean-newsletter-recherche {
    .btn.btn-red {
      White-Space: normal;
      margin: auto !important;
    }
  }
}
@media only screen and (max-width: 1023px)  {
  #navbar .header-top .header-content.container {
    width: auto !important;
  }
}
@media only screen and (max-width: 1200px)  {
  .header-right:before {
    left: -75px !important;
    border-width: 50px 0 150px 75px !important;
  }
  .header-top #block-bean-contacts {
    height: 100%;
    position: relative;
    padding-top: 18px;
    padding-left: 30px;
    &:before {
      margin-left: -20px;
    }
  }
}
@media only screen and (min-width: 1430px) and (max-width: 1594px) {
  .header-left {
    width: 65%;
  }
  .header-right {
    width: 35%;
  }
}
@media only screen and (min-width: 1595px) and (max-width: 1994px) {
  .header-left {
    width: 62%;
  }
  .header-right {
    width: 38%;
  }
}
@media only screen and (min-width: 1995px)  {
  .header-left {
    width: 60%;
  }
  .header-right {
    width: 40%;
  }
}

/*
=======================================================================
    Icônes web social header + footer
=======================================================================
*/
.social-icons a.accessibility {
  span {
    display: none;
  }
  background: none repeat scroll 0 0 #5B5B5B;
  color: #FFF;
  text-decoration: none;
  font-size: 1.3em;
  display: inline-block;
  width: 29px;
  height: 29px;
  border-radius: 20px;
  padding: 7px;
  line-height: 1;
}

/* ----- HEADER ----- */
.header .social-icons {
  position: absolute;
  top: 10px;
  left: -35px;
}

/* ----- FOOTER ----- */
footer {
  .social-icons a.accessibility {
    background: none repeat scroll 0 0 #009ee3;
    color: #006699;
    margin-right: 10px;
  }
  #block-bean-web-social-footer {
    float: right;
  }
}

/*
=======================================================================
    Menu
=======================================================================
*/
#navbar {
  .navbar-collapse {
    background: none repeat scroll 0 0 #5B5B5B !important;
    border: none !important;
    border-radius: 0 !important;
  }
  .navbar-collapse .menu {
    float: left;
    padding: 1px;
    li {
      float: left;
      position: relative;
      &.leaf a:before {
        content: "";
        height: 30px;
        border-right: 1px solid #848484;
        position: absolute;
        right: 0;
        top: 7px;
      }
      &:nth-child(1) a:before {
        border-left: 1px solid #848484;
        left: -1px;
      }
      &.leaf:last-child a:before {
        border-right: none !important;
      }
      a {
        text-transform: uppercase;
        color: #FFF;
        font: 14px Helvetica,sans-serif;
        text-decoration: none;
        padding: 15px 20px;
      }
      &:hover > ul {
        display: block;
      }
    }
    a:hover, ul li:nth-child(3) a:hover {
      background-color: #C81E32 !important;
      font-weight: bold;
    }
    li:nth-child(3) a:hover {
      background-color: #5a185a !important;
    }
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0;
    }
  }
}

.affix {
  position: fixed;
  width: 100%;
}

#navbar .navbar-collapse .menu li{
  &.first.last.expanded > a {
    &:before {
      content: "\f13a";
      font-family: fontawesome;
      position: absolute;
      top: -2px;
      font-size: 1.4em;
      margin: auto;
      border-left: medium none;
      left: 103px;
      width: 25px;
      height: 25px;
      text-align: center;
    }
    background-color: #C81E32;
    color: #FFF;
    border: 1px solid #C81E32;
    border-radius: 15px;
    text-transform: uppercase;
    padding: 2px 35px 2px 15px !important;
    margin: 10px 15px 12px 0;
  }
  ul {
    z-index: 99;
    width: 350px;
    display: none;
    position: absolute;
    top: 45px;
    left: -8px;
    background-color: #FFF;
    border: 1px solid #848484;
    box-shadow: 2px 2px 2px 0 #5a5a5a;
    li {
      float: none;
      box-shadow: 0 1px 0 #5a5a5a, 0 1px 0 #777777;
      &:last-child {
        box-shadow: none;
      }
    }
    a {
      &:before {
        border-right: none !important;
        border-left: none !important;
        content: "\f138" !important;
        font-family: fontawesome;
        position: absolute;
        top: 0;
        font-size: 1.5em;
        margin: auto;
        left: 325px !important;
      }
      padding: 10px;
      line-height: 1;
      text-transform: uppercase;
      color: #5a5a5a;
      &:hover {
        background-color: #C81E32 !important;
        color: #FFF;
      }
    }
  }
}
#navbar {
  #main-menu.collapse.in .menu.nav {
    li {
      &.first.last.expanded > a {
        background-color: transparent;
        border: none;
        padding: 15px 20px !important;
        margin: 0;
        border-radius: 0;
      }
      ul {
        width: auto;
        float: none;
        position: relative;
        background-color: transparent;
        border: none;
        box-shadow: none;
        top: 0;
        left: 0;
        li {
          box-shadow: none;
          a {
            color: #FFF;
          }
        }
      }
      float: none;
    }
    float: none;
  }
  #main-menu.collapsing .menu.nav {
    li {
      float: none;
      .first.last.expanded > a {
        background-color: transparent;
      }
    }
    float: none;
  }
}
#navbar #main-menu.collapse.in .menu li a:before ,
#navbar #main-menu.collapsing .menu li a:before{
  content: none !important;
}
.navbar.collapse.navbar-collapse.affix {
  top: 0;
  width: 100% !important;
  z-index: 99999;
}

/*
=======================================================================
   Breadcrumb
=======================================================================
*/
.frise {
  background-color: #ECECEC;
  background-image: url('../frise.png');
  background-size: 100% 20px;
  background-repeat: repeat-x;
  height: 25px;
  background-position: center center;
  padding: 10px 0;
  position: relative;
  .breadcrumb {
    position: relative;
    z-index: 5;
    background: none repeat scroll 0 0 #ECECEC;
    text-transform: uppercase;
    padding: 4px 10px;
    margin-top: -10px;
    border-radius: 0;
    font-size: 0.857em;
    .breadcrumb-list {
      list-style: none;
      display: inline-block;
      > li {
        display: inline-block;
      }
    }
  }
}

/*
=======================================================================
    Slider Accueil
=======================================================================
*/

/* -------- Flèches de navigation ------- */
.slider{
  &.hmax {
    max-height: 430px;
  }
  overflow: hidden;
  .owl-nav {
  }
  .owl-prev, .owl-next{
    position: absolute;
    top: 40%;
    width: 30px;
    height: 30px;
    border-radius: 20px !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    &:hover {
      width: 30px;
      height: 30px;
      border-radius: 20px;
      background-color: #C81E32 !important;
    }
    .fa {
      font-size: 1.4em !important;
      position: absolute;
      top: 6px;
    }
  }
  .owl-prev{
    left: 3%;
    .fa {
      left: 6px;
    }
  }
  .owl-next{
    right: 3%;
    .fa {
      right: 6px;
    }
  }
}

/* ------- contenu du slider ------- */
.slider{
  text-decoration: none;
  .titre {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFF;
  }
  .pull-right {
    font-weight: normal;
    font-size: 0.8em;
    padding-right: 25px;
    text-transform: none;
    &:hover {
      color: #C81E32;
    }
  }
}

/* -------- boutons d'entrée de droite ------- */
.item {
  position: relative;
}
/* hack Chrome / Safari */
@media screen and (-webkit-min-device-pixel-ratio:0) {
  .slider-buttons-right {
    margin-top: 55px !important;
  }
}
.slider-buttons-right {
  margin-top: 56px;
  color: #FFF;
  text-transform: uppercase;
  height: 366px;
  .item{
    height: 33.3333%;
    &:hover {
      background-color: #C81E32 !important;
    }
    .title {
      font-size: 1.5em;
      padding: 35px 10px 0;
    }
    .subtitle {
      font-size: 1.2em;
      padding: 0 10px;
    }
  }
  .item:first-child {
    background-color: #5B5B5B;
  }
  .item:nth-child(2) {
    background-color: #006699;
  }
  .item:last-child {
    background-color: #0099E3;
  }
}

@media only screen and (max-width: 767px)  {
  .slider-buttons-right {
    height: auto !important;
    margin-top: 20px;
    .title {
      padding: 10px 10px 0 !important;
    }
    .subtitle {
      padding: 0px 10px 10px !important;
    }
   /* .item:first-child, .item:last-child  {
      display: none !important;
    }*/
  }
}
@media only screen and (min-width: 1200px)  {
  .slider-buttons-right {
    height: 429px !important;
  }
}

/*
=======================================================================
    Vue page : bloc carrefour, bloc pages_soeurs
=======================================================================
*/

/* home : boutons d'entrée mauves */
.home.entree-mauves {
  .items {
    margin-top: 30px;
    color: #5A185A;
    .thumbnail {
      border-radius: 0;
      border-color: #5A185A;
      height: 278px;
      &:hover {
        color: #AC8BAC;
        border-color: #AC8BAC;
        &:before {
          color: #5A185A;
        }
      }
      &:before {
        content: "\f138";
        font-family: FontAwesome;
        color: #5A185A;
        font-size: 1.5em;
        position: absolute;
        top: 5px;
        right: 15px;
        height: 25px;
        width: 25px;
      }
      .h4 {
        text-align: center;
        text-transform: uppercase;
        padding: 0 20px;
        line-height:1.4em;
      }
      .icone{
        max-width:60%;
        text-align: center;
        margin:auto;
      }
    }
  }
}
@media only screen and (max-width: 767px)  {
  .home.entree-mauves {
    .items {
      margin-top: 10px;
      .thumbnail {
        height: auto;
        margin-bottom: 10px;
      }
    }
  }
}
@media only screen and (max-width: 1023px)  {
  .home.entree-mauves {
    .items {
      .thumbnail {
        .icone {
          max-width:50%;
        }
      }
    }
  }
}

/* Bloc page carrefour (boutons d'entrée (bleus) vers les pages de niveau 3) */
.page-carrefour-item {
  > .thumbnail {
    padding: 0;
    background: none repeat scroll 0 0 transparent;
    border: 0 none;
    margin-bottom:10px;
    > a.top {
      width: 100%;
      height: 210px;
      display: block;
      position: relative;
      background: none repeat scroll 0 0 #006699;
      overflow: hidden;
      color: #F4F4F4;
      text-decoration: none;
      text-align: left;
      padding-left: 20px;
      padding-right: 20px;
      &:before {
        content: "\f138";
        font-family: FontAwesome;
        color: #fff;
        font-size: 1.5em;
        position: absolute;
        top: 5px;
        right: 0;
        height: 25px;
        width: 25px;
      }
      &:hover, &:active{
        width: 100%;
        background: #C81E32;
        overflow: hidden;
        text-decoration: none;
      }
      &:hover h2, &:active h2 {
        font-weight: bold;
      }
      > h2 {
        font-size: 1.143em;
        padding-bottom: 15px;
        color: #F4F4F4;
        text-transform: uppercase;
        line-height: 1.4em;
        margin-top: 25px;
        padding-right: 5px;
      }
      &.bg-mauve{
        background-color: #5A185A;
        &:hover, &:active{
          background-color: #006699;
        }
      }
    }
    .caption {
      padding: 9px;
      color: #333;
    }
  }
}

.p-924 .page-carrefour-item {
  > .thumbnail {
    > a.top {
      background: none repeat scroll 0 0 #5A185A;
      &:hover, &:active{
        background: #006699;
      }
    }
  }
  .top.active {
    background-color: #006699 !important;
  }
}
.p-924 {
  .contenu-principal a {
    color: #5A185A;
  }
  .btn-default.btn-red, .btn-red {
    background-color: #5A185A;
    color: #FFF !important;
    border: 1px solid #5A185A;
    border-radius: 15px;
    text-transform: uppercase;
    padding: 0 15px !important;
    margin: 10px;
    background-image: none;
  }
  .btn-default.btn-red:hover, .btn-default.btn-red:active, .btn-default.btn-red:focus, .btn-red:hover, .btn-red:active, .btn-red:focus {
    background-color: #FFF;
    color: #5A185A !important;
    border: 1px solid #5A185A;
    border-radius: 15px;
    text-transform: uppercase;
    padding: 0 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .page-carrefour-item .thumbnail > a.top {
    height: auto;
  }
}

.mot-cles-container{
  color: white;
  padding: 2px;
  margin: 26px 0;
  .mot-cles a {
    color: #FFF;
    &:hover {
      color: #ECECEC;
    }
  }
}
.mot-cle-titre{
  font-size: 1.3em;
  font-weight: bold;
}
.intro {
  font-size: 1.3em;
}

/* ------- Bloc pages_soeurs ------- */
.page-carrefour-item {
  .top.active {
    background-color: #C81E32 !important;
  }
}

/*
=======================================================================
    Actualités
=======================================================================
*/
.actu {
  min-height: 150px;
  max-height: 550px;
  overflow: hidden;
}
.actu-listing {
  .actu-row-color{
    &:nth-child(2n+1) {
      background-color:#006699;
    }
    &:nth-child(2n) {
      background-color:#009EE3;
    }
    &:hover {
      background-color:#C81E32;
    }
  }
  .actu-list-row {
    position: relative;
    color: #FFF;
    min-height:100px;
    /* IMG{
       height: 250px;
       width: 100%;
     }*/
    .item-meta {
      margin: 10px 0;
    }
    .read-more {
      .fa {
        font-size: 1.7em;
        float: right;
        margin-top: 10px;
      }
    }
    .item-title.h3:hover {
      font-weight: bold;
    }
  }
}
.actu-container {
  .actu-social {
    text-align: right;
    padding: 20px 0;
    font-size: 1.6em;
    .fa{
      width: 35px;
      height: 35px;
      border-radius: 25px;
      background-color: #006699;
      text-align: center;
      line-height: 1.5;
      color: #FFF;
      margin-left: 5px;
    }
  }
  .actu-img-principale IMG{
    width: 100%;
  }
  .intro {
    text-align: justify;
  }
  .content {
    text-align: justify;
  }
  .actu-links{
    text-align: right;
  }
  a.same-subject{
    text-transform: uppercase;
  }
  .conteneur {
    padding: 10px 50px;
  }
}
.see-all {
  white-space: normal;
}

/* ----- affichage de la vidéo ----- */
.video-actu-bas .media-youtube-player {
  display: block;
  margin: auto;
  width: 80%;
}

/*
===============================================================================
   Légendes images
===============================================================================
*/
.texte-image .legende{
  color: #464646;
  font-style: italic;
  margin-bottom:20px;
}

/*
===============================================================================
    Agenda :
   - contient les styles du node--agenda.tpl.php, et des vue-agenda
===============================================================================
*/
.content-evenement {
  .row.m-t-20 {
    display: flex;
  }
  .date-display-range {
    display: inline-block;
  }
  .texte {
    padding: 10px;
    width: 100%;
  }
  .bg-blue.is-white {
    height: 100%;
    font-size: 1.15em;
    .texte {
      text-align: center;
      padding: 20px 0;
      text-transform: uppercase;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 992px)  {
  .content-evenement .row.m-t-20 {
    display: block !important;
  }
}

/* ----- bloc agenda accueil ----- */
.agenda-home {
  .bg-blue.m-b-10:hover {
    background-color: #C81E32;
  }
  .item {
    a {
      .date {
        color: #006699;
      }
      .titre {
        margin-left: 15px;
        color: #464646;
      }
      &:hover {
        .date {
          color: #009EE3;
        }
        .titre {
          color: #848484;
        }
      }
    }
  }
  > hr:last-of-type {
    display: none;
  }
}
/* ----- page agenda ----- */
.agenda-page {
  .row {
    &:hover {
      background-color: #C81E32;
      .top {
        .titre, .objet, .more-link {
          color: #FFF;
        }
        .titre {
          font-weight: bold;
        }
      }
    }
    .top {
      display: flex;
      width: 100%;
      .date, .lieu {
        background-color: #464646;
        color: #FFF;
        font-size: 1.15em;
        padding: 15px;
      }
      .date {
        background-color: #009EE3 !important;
      }
      .objet, .more-link {
        color: #464646;
        padding: 5px 20px;
      }
      .titre {
        color: #006699;
        padding: 5px 20px;
        font-size: 1.2em;
      }
    }
  }
}

@media only screen and (max-width: 767px)  {
  .agenda-page .row .top {
    display: block !important;
  }
}

/* ----- pagination ----- */
.view-id-recherche .view-display-id-agenda .pagination-wrap .pagination {
  float: right;
  > li > a {
    margin-right: 5px;
    border-radius: 0;
    background-color: #848484;
    color: #FFF;
    &:hover {
      background-color: #C81E32;
    }
  }
  .active a {
    background-color: #009EE3;
  }
}

/* ----- formulaire de recherche ----- */
.formulaire-recherche-agenda {
  .date-padding .control-label { display: none !important;}
  .entete {
    color: #006699;
    font-size: 2.5em;
    text-transform: uppercase;
  }
  .widgets {
    padding: 30px 10px;
    margin-bottom: 50px;
    background-color: #009EE3;
    .form-control, .form-group, #edit-field-date-debut-value, #edit-field-date-fin-value {
      width: 100% !important;
    }
    .col-sm-6.col-xs-12 {
      margin-bottom: 10px;
    }
    .form-select {
    /*  height: 100%;
      background-color: transparent;
      border: none !important;
      box-shadow: none;
      padding: 0;*/
    }
    .label {
      font-size: 1em;
      .fa {
        font-size: 3em;
        right: -26px;
        position: relative;
        bottom: -12px;
      }
    }
    .submit-button {
      text-align: center;
      #edit-submit-recherche-agenda {
        border: none;
        border-radius: 0;
        background-color: #006699;
        color: #FFF;
        position: relative;
        bottom: -20px;
        background-image: none;
        &:hover {
          background-color: #C81E32;
          font-weight: bold;
        }
      }
    }
  }
}

/*
=======================================================================================
    ANNUAIRES
   - contient les styles du node--entreprise.tpl.php + node--ancien-etudiant.tpl.php
    et des (vues) entreprise--annuaire-reseau-pro + entreprise-annuaire-exportateurs
=======================================================================================
*/
.node-entreprise {
  .mot-cles a {
    color: #FFF;
    &:hover {
      color: #C81E32;
    }
  }
  .thumbnail {
    padding: 15px 15px 15px 0;
    box-shadow: none;
    .top {
      width: 100%;
      height: 210px;
      display: block;
      position: relative;
      background: none repeat scroll 0 0 #006699;
      overflow: hidden;
      color: #F4F4F4;
      text-decoration: none;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      text-transform: uppercase;
      font-size: 1.5em;
      > span {
        position: relative;
        top: 40%;
      }
    }
  }
  .texte {
    padding: 15px;
    line-height: 3;
    text-transform: uppercase;
    margin-top: 15px;
    .mail, .siteweb{
      text-transform: none;
    }
  }
}

@media only screen and (max-width: 992px) {
  .node-entreprise .thumbnail {
    .top {
      height: auto;
    }
    padding: 0;
  }
  .node-entreprise .texte {
    line-height: 2;
    margin-top: 0;
  }
}

.annuaire {
  .is-white {
    text-transform: uppercase;
    padding: 5px;
  }
  .listing {
    .item {
      padding: 15px;
      margin: 15px 0;
      color: #FFF;
      &:nth-child(2n+1){
        background-color: #006699;
      }
      &:nth-child(2n){
        background-color: #009EE3;
      }
      &:hover {
        background-color: #C81E32;
      }
      .fa {
        position: absolute;
        right: 25px;
        font-size: 1.5em;
      }
      .titre {
        font-size: 1.2em;
        text-transform: uppercase;
      }
    }
  }
}

/* ----- Filtres exposés ----- */
.formulaire-recherche-entreprise {
  .entete {
    font-size: 1.3em;
    text-transform: uppercase;
    color: #FFF;
    background-color: #A5A5A5;
    padding: 5px 20px;
  }
  .widgets {
    background-color: #C81E32;
    padding: 15px;
    .label {
      font-size: 1em;
    }
    .chosen-container.chosen-container-single.form-control {
      background : transparent none repeat scroll 0 0;
      box-shadow: none;
      border: none;
      width: 100% !important;
      padding: 0;
    }
    .chosen-container-single .chosen-drop {
      margin-top: -10px;
    }
  }
  .submit-button {
    background-color: #C81E32;
    #edit-submit-entreprises, #edit-submit-anciens-etudiants  {
      padding: 5px;
      background: transparent none repeat scroll 0 0;
      border: medium none;
      color: #FFF;
      font-size: 1.2em;
      text-transform: uppercase;
      width: 100%;
    }
    &:hover {
      opacity: 0.9;
    }
    &:after {
      top: 100%;
      left: 50%;
      border: 1px solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: #C81E32;
      border-width: 9px 10px 10px 10px;
      margin-left: -10px;
    }
  }
}

/*
=======================================================================
    ANNONCES : Contient les styles nécessaires à la mise en forme des
    contenu et des vues en relation avec le type de contenu 'annonce'
=======================================================================
*/
.annonce-bouton-entree {
  .top {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
.annonce-img-principale IMG{
  padding: 0 10px;
}
.annonce {
  min-height: 150px;
  max-height: 350px;
  overflow: hidden;
}
.prix {
  font-size: 1.2em;
}
.bouton-action {
  position: relative;
  height: 45px;
  .btn-add-annonce {
    position: absolute;
    right: 0;
    font-size: 1.2em;
    white-space: normal;
  }
}

/*
==============================================================================
       Vidéo Home
==============================================================================
*/
.video-home {
  .bg-red:hover {
    background-color: #006699;
  }
}
.bg-titre-bloc-home {
  padding: 5px 15px;
  position: relative;
  color: #FFF;
  text-transform: uppercase;
}

/*
==============================================================================
    Page-accordéon (Styles pour l'affichage des accordéons) + Blocs_sidebar
==============================================================================
*/
.content-accordion {
  .panel-group {
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  .panel-default {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .panel-heading {
    border: none !important;
    border-radius: 0 !important;
  }
  .panel-heading.accordion-actif {
    background-color: #C81E32 !important;
    color: #FFF !important;
    background-image: none;
  }
  .panel-heading.accordion-inactif {
    background-color: #464646 !important;
    color: #FFF !important;
    background-image: none;
  }
  .indicator {
    font-size: 1.4em;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    height: 100%;
    right: 0px;
  }
  .panel-title {
    position: relative;
    line-height: 1.4em;
    .accordion-lien {
      padding-right: 30px;
    }
  }
}

.p-924 .content-accordion {
  .panel-heading.accordion-actif {
    background-color: #5A185A !important;
  }
}

/* ----- Blocs dans la sidebar right ----- */
.side_bar_right {
  > div {
    background-color: #009EE6;
    color: #FFF;
    padding: 5px;
    margin: 0 0 20px;
    .titre {
      font-size: 1.5em;
      margin-bottom: 10px;
    }
    .top {
      color: #FFF;
      margin-bottom: 5px;
      &:hover {
        color: #C81E32 !important;
      }
      .fa {
        color: #FFF;
      }
    }
    .mail, .telephone {
      > a {
        color: #FFF;
        &:hover {
          color: #C81E32;
        }
      }
    }
    .nom-prenom{
      font-size:1.4em;
    }
  }
  .node-texte-image-lien{
    position: relative !important;
    &:hover {
      background-color: #C81E32;
    }
  }
}

.p-924 .side_bar_right {
  > div {
    background-color: #5A185A;
    .top {
      &:hover {
        color: #009EE6 !important;
      }
    }
    .mail, .telephone {
      > a {
        &:hover {
          color: #009EE6;
        }
      }
    }
  }
  .node-texte-image-lien{
    position: relative !important;
    &:hover {
      background-color: #009EE6;
    }
  }
}


.side_bar_right .map div{
  color:#777;
}

.sidebar-blocs {
  .titre {
    .fa {
      transform: rotate(30deg);
      font-size: 1.8em;
    }
  }
  .nom {
    display: inline-block;
  }
  .link {
    color: #FFF;
    &:hover {
      color: #C81E32;
    }
  }
  div.node.node-contenus-telechargeables {
    background-color: #464646 !important;
    .file.file-document {
      background-color: transparent;
      text-align: center;
      .fa {
        width: 100%;
        font-size: 5px;
      }
      .filefield-title, .filefield.file-size {
        color: #FFF;
      }
    }
  }
}
.mail, .telephone {
  font-size: 1.25em;
  word-wrap: break-word;
}
.mail {
  font-size: 1em;
}

/*
=======================================================================
    FAQ
=======================================================================
*/
.node-faq {
  .question {
    font-size: 2em;
    margin: 10px;
  }
  .rep_courte {
    padding: 5px;
  }
  .rep_detaillee {
    padding: 5px;
  }
}
#colorbox .node-faq {
  margin-top: 60px;
  margin-left: 20px;
}
.bloc-faq {
  .question > a{
    color: #FFF;
    &:hover {
      color: #C81E32;
    }
  }
}


/*
=======================================================================
    SurFooter : Carousels Prestation et Services + Partenaires
=======================================================================
*/

/* ------ Flèches de navigation ----- */
.carousel-surfooter{
  max-height: 250px;
  .owl-nav {
    position: absolute;
    top: -50px;
    right: 0;
    font-size: 1.3em;
    text-align: center;
  }
  .owl-prev, .owl-next{
    width: 25px;
    height: 25px;
    background-color: #848484 !important;
    border-radius: 0 !important;
    &:hover {
      width: 25px;
      height: 25px;
      background-color: #C81E32 !important;
    }
  }
}

/* ------- éléments + contenus du carousel ------ */
.surfooter.prestation-service-item, .surfooter.partenaire-item {
  .thumbnail {
    border: none;
    padding: 0;
    margin-bottom: 0;
    box-shadow: none;
    position: relative;
  }
  .top {
    width: 100%;
    display: block;
    overflow: hidden;
    color: #F4F4F4;
    text-decoration: none;
    margin: auto;
  }
  .titre {
    position: absolute;
    bottom: 0;
    color: #FFF;
    font-size: 1.25em;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
  }
}
.surfooter.partenaire-item {
  .thumbnail > img {
    border: 1px solid #5A5A5A;
  }
}

@media only screen and (max-width: 767px) {
  .view.view-prestations-services, .view.view-partenaires{
    .h3.is-blue {
      font-size: 1.286em;
    }
  }
}

/*
=======================================================================
    Footer
=======================================================================
*/
footer {
  a {
    color: #fff;
  }
  .footer-content a:hover {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
  }
  > .top {
    color: #FFF;
    font-size: 0.929em;
  }
  .top .footer-left, .middle .footer-right {
    background-color: #006699;
  }
  .top .footer-right {
    background-color: #a5a5a5;
  }
  .middle .footer-left {
    background-color: #5b5b5b;
  }
  .bottom {
    background-color: #787878;
  }
  > {
    .top, .middle, .bottom {
      position: relative;
      min-height: 20px;
    }
  }
  .footer-right {
    left: 75%;
    height: 100%;
    position: absolute;
    width: 25%;
    right: 0;
    z-index: -1;
  }
  .footer-left {
    height: 100%;
    position: absolute;
    width: 75%;
    left: 0;
    z-index: -1;
  }
  .middle #block-bean-ccism-map-0 {
    color: #FFF;
  }
  .bottom ul.menu li a {
    &:hover, &:focus, &:active {
      color: #fff;
      text-decoration: none;
      background-color: transparent;
    }
  }
  .middle {
    .footer-left {
      a {
        &:hover, &:focus, &:active {
          color: #fff;
          text-decoration: none;
          background-color: transparent;
        }
      }
      .region-footer-middle-left {
        margin-left: 25px;
      }
    }
    ul.menu {
      ul li.leaf a {
        font-weight: normal;
        padding-bottom: 0;
        border-bottom: none;
        text-transform: none;
      }
      li.expanded {
        margin-bottom: 20px;
        a {
          font-weight: 900;
          padding-bottom: 5px;
          border-bottom: 2px solid #ECECEC;
          text-transform: uppercase;
          font-size: 0.786em;
        }
      }
    }
  }
  .bottom .h2, .middle .h2 {
    display: none;
  }
  .bottom {
    text-align: center;
    padding: 5px 0;
    .menu li {
      display: inline-block;
      a {
        border-right: 2px solid #fff;
        padding: 0 15px;
        font-weight: 600;
      }
      &:last-child a {
        border-right: none !important;
      }
    }
  }
}
@media only screen and (max-width: 1023px) {
  footer {
    .top .footer-left {
      height: 100%;
      position: absolute;
      width: 100%;
      left: 0;
      z-index: -1;
    }
    .middle .footer-right {
      left: 0;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }
}

/* ----- Footer contact / localiser ----- */
footer {
  .mobile  {
    line-height: 50px;
    font-size: 1.3em;
    font-weight: bold;
    color: #FFF;
    .fa {
      vertical-align: middle;
      margin-right: 5px;
      border-radius: 20px;
      width: 30px;
      height: 30px;
      font-size: 1.5em;
      margin-left: 10px;
      text-align: center;
      background-color: #FFF;
      color: #A5A5A5;
      line-height: 30px;
    }
    .bg-red .fa {
      color: #C81E32 !important;
    }
    .col-xs-6.bg-lightgray:before {
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: -25px;
      content: "";
      border-top: 50px solid transparent;
      border-right: 25px solid #A5A5A5;
    }
  }
  a:hover {
    color: #C81E32;
  }
}
@media only screen and (max-width: 767px)  {
  footer {
    .mobile  {
      font-size: 1.1em;
      .fa {
        margin-right: 0;
        margin-left: 0;
        border-radius: 20px;
        width: 1.2em;
        height: 1.2em;
        font-size: 1.2em;
        line-height: 1.2em;
      }
      .col-xs-6.bg-red {
        padding: 5px !important;
      }
      .col-xs-6.bg-lightgray {
        padding: 5px !important;
        &:before {
          left: -20px;
          border-top: 60px solid transparent;
          border-right: 20px solid #A5A5A5;
        }
      }
    }
    a:hover {
      color: #C81E32;
    }
  }
}

/*
============================================
  Sitemap
============================================
*/
#site-map .description {
  display:none;
}
.site-map-books > ul > li {
  margin-bottom:10px;
}
.site-map-books ul ul ul ul{
  display:none;
}
#site-map .site-map-box ul ul {
}

.site-map-books li a {
  text-decoration: none;
  font-size:1em;
}

.site-map-books li li {
  margin-top: 50px;
  a {
    text-decoration: underline;
    font-size: 1.2em;
  }
}
.site-map-books li li li {
  margin-top: 0px;

}
.site-map-books li li li a {
  text-decoration: none;
  font-size:1em;
}
.site-map-books li li li li {
  margin-top: 0px;

}
.site-map-books li li li li a {
  text-decoration: none;
  font-size:1em;
}


/*
============================================
  Cookies
============================================
*/
#sliding-popup.sliding-popup-bottom {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
#sliding-popup .popup-content #popup-text {
  margin: 0;
  padding: 10px;
  max-width: 80%;
  font-weight: normal;
  .link {
    color: #FFF !important;
    font-weight: bold;
    &:hover {
      color: #C81E32 !important;
    }
  }
}
#sliding-popup .popup-content #popup-buttons {
  .find-more-button {
    display: none;
  }
  .agree-button {
    margin: 10px 0;
    box-shadow: none;
    border: 1px solid #FFF;
    background: transparent none repeat scroll 0 0;
    color: #FFF;
    text-shadow: none;
    padding: 0 8px;
    &:hover {
      color: #C81E32;
      border-color: #C81E32;
      background: transparent none repeat scroll 0 0;
    }
  }
}

